.availability-calendar .react-calendar {
    border: none;
}

.availability-calendar .react-calendar__navigation {
    color: black;
}

.availability-calendar .react-calendar__month-view__days button:enabled:hover {
    cursor: pointer;
}

.availability-calendar .react-calendar__month-view__days {
    display: grid!important;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 10px 0;
}

.availability-calendar .react-calendar__month-view__days__day.react-calendar__tile {
    flex: unset!important;
    padding: 0;
    outline: 0;
    height: 36px;
    width: 36px;
    color: white;
    margin-left: 50%;
    transform: translateX(-50%);
}

.availability-calendar .react-calendar__month-view__days__day.react-calendar__tile:hover {
    background-color: #f97316;
}

.availability-calendar .react-calendar__month-view__days__day.react-calendar__tile.react-calendar__tile--active {
    background-color: #f97316;
    border: 2px solid #93c5fd;
}

.availability-calendar .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 75%;
}

.availability-calendar .react-calendar__month-view__days__day.react-calendar__tile:disabled {
    background-color: #EF4444;
    opacity: 50%;
}
